import React from 'react'

const Home = () => {
  return (
    <div>
      <section id="home">
        {/* Content for Home section */}
        {/* You can place content directly here or use components */}
        <h2>Hi, I'm Sam!</h2>
        <h3>I develop apps, create UI/UX, design graphics, and make videos.</h3>
        <p>On the front-end, I develop with Javascript using React & React Native frameworks.</p> 
        <p>On the back-end, I develop with node.js, express and use MonogoDB.</p> 
        <p>When it comes to design, I specialize in Adobe Suite, Final Cut Pro, etc.</p>
        <p>Currently I'm a freelancer, and I also am working with <a href="https://www.publicservicetelevision.org/" target="_blank" rel='noreferrer'>Public Service Television</a> to share excellent, meaningful stories throughout the world. </p>
      </section>
    </div>
  )
}

export default Home
