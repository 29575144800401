import React from 'react';

const About = () => {
  return (
    <section style={aboutStyle}>
      <h2>About Me</h2>
    </section>
  );
};

const aboutStyle = {
  background: 'gray',
  padding: '50px 20px',
  textAlign: 'center',
};

export default About;